<template>
  <div ref="element" class="dropdown" :class="{ opened }">
    <div class="wrapper" @click="toggle">
      <slot name="wrapper" v-bind="{ opened, toggle }" />
    </div>
    <transition name="fade">
      <div v-if="opened" class="content" @mouseleave="toggle()">
        <slot name="content" v-bind="{ opened, toggle }" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
const element = $ref<HTMLDivElement>();
let opened = $ref<boolean>(false);

onMounted(() => document.addEventListener('click', onClick));
onBeforeUnmount(() => document.removeEventListener('click', onClick));

function onClick(event: MouseEvent): void {
  if (!element?.contains(event.target as Node)) {
    opened = false;
  }
}

function toggle(): void {
  opened = !opened;
}
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/animation.scss';
@use '$/colors.scss';

.dropdown {
  position: relative;

  .content {
    @include animation.vue-transition-fade(0.15s);

    position: absolute;
    z-index: 10;
    top: calc(100% + 8px);
    left: 0;

    align-items: start;

    min-width: 100%;
    padding: 16px;

    font-size: 18px;
    font-weight: 400;
    color: colors.$primary-black;

    background: white;
    border: 1px solid colors.$primary-black;
    border-radius: 4px;

    @include breakpoints.mobile() {
      transform: translateX(-45%);
    }
  }
}
</style>
